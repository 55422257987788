import React, { useRef, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { projects } from '../config/constants';
import Footer from './Footer'; // Import the Footer component
import About from './About';
import Gallery from './Gallery'; // Import the Gallery component
// New component for project detail sections
const ProjectDetailSection = ({ title, content }) => (
  <div className="mb-8">
    <h3 className="font-normal text-sm text-gray-400">{title}</h3>
    <p className="leading-relaxed">{content}</p>
  </div>
);

// Utility function to get the first frame of a GIF
const getFirstFrameOfGif = (gifUrl) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function() {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      canvas.getContext('2d').drawImage(this, 0, 0, this.width, this.height);
      resolve(canvas.toDataURL());
    };
    img.src = gifUrl;
  });
};

function ProjectPage() {
  const { id } = useParams();
  const project = projects.find(p => p.id === id);
  const rightColumnRef = useRef(null);
  const leftColumnRef = useRef(null);
  const [scrollThreshold, setScrollThreshold] = useState(0);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [galleryStartIndex, setGalleryStartIndex] = useState(0);
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    const calculateScrollThreshold = () => {
      if (leftColumnRef.current) {
        // Set the scroll threshold to 20% of the left column's height
        setScrollThreshold(leftColumnRef.current.offsetHeight * 0.2);
      }
    };

    calculateScrollThreshold();
    window.addEventListener('resize', calculateScrollThreshold);

    const handleScroll = () => {
      if (rightColumnRef.current && leftColumnRef.current) {
        const scrollY = window.scrollY;
        const leftColumnHeight = leftColumnRef.current.offsetHeight;
        const rightColumnHeight = rightColumnRef.current.offsetHeight;
        const windowHeight = window.innerHeight;
        
        if (rightColumnHeight > windowHeight && scrollY > scrollThreshold) {
          const maxScroll = leftColumnHeight - windowHeight;
          const maxRightScroll = rightColumnHeight - windowHeight;
          const adjustedScrollY = scrollY - scrollThreshold;
          const scrollPercentage = Math.min(adjustedScrollY / (maxScroll - scrollThreshold), 1);
          const rightScrollAmount = maxRightScroll * scrollPercentage;
          
          rightColumnRef.current.style.transform = `translateY(-${rightScrollAmount}px)`;
        } else {
          rightColumnRef.current.style.transform = 'translateY(0)';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', calculateScrollThreshold);
    };
  }, [scrollThreshold]);

  useEffect(() => {
    if (project && project.image) {
      const setBackground = async () => {
        let imageUrl = project.image;
        if (project.image.toLowerCase().endsWith('.gif')) {
          imageUrl = await getFirstFrameOfGif(project.image);
        }
        setBackgroundImage(imageUrl);
      };
      setBackground();
    }
  }, [project]);

  useEffect(() => {
    if (backgroundImage) {
      setBackgroundStyle({
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        filter: 'blur(50px)',
        opacity: 0.2,
      });
    }
  }, [backgroundImage]);

  if (!project) {
    return <div className="text-white">Project not found</div>;
  }

  const { title, subtitle, image, description, additionalImages, technologies, link, role, year, client, agency, type, objective, challenge, solution, execution, impact } = project;

  const allImages = [image, ...(additionalImages || [])];

  const openGallery = (index) => {
    setGalleryStartIndex(index);
    setGalleryOpen(true);
  };

  return (
    <>
      <div className="relative min-h-screen">
        <div 
          className="absolute inset-0 z-0" 
          style={backgroundStyle}
        ></div>
        <div className="relative z-10 text-white p-8 max-w-7xl mx-auto">
          <Link to="/" className="text-gray-400 mb-8 block hover:underline">&larr; Back to Home</Link>
          <div className="flex flex-col lg:flex-row gap-12">
            <div className="lg:w-1/2 space-y-8" ref={leftColumnRef}>
              <div>
                <h1 className="text-4xl mb-2">{title}</h1>
                {subtitle && <p className="text-xl text-gray-300">{subtitle}</p>}
              </div>
              <p className="text-xl leading-relaxed">{description}</p>

              {/* New styled sections */}
              <div className="space-y-8 mt-8 border-t border-gray-700 pt-8">
                {objective && <ProjectDetailSection title="Objective" content={objective} />}
                {challenge && <ProjectDetailSection title="Challenge" content={challenge} />}
                {solution && <ProjectDetailSection title="Solution" content={solution} />}
                {execution && <ProjectDetailSection title="Execution" content={execution} />}
                {impact && <ProjectDetailSection title="Impact" content={impact} />}
              </div>
            </div>

            <div className="lg:w-1/2 space-y-8 lg:sticky lg:top-8 lg:self-start mt-8 lg:mt-0" ref={rightColumnRef}>
              <div className="aspect-w-16 aspect-h-9 cursor-pointer" onClick={() => openGallery(0)}>
                <img src={image} alt={title} className="w-full h-full object-cover rounded-lg shadow-lg" />
              </div>
              
              {additionalImages && additionalImages.length > 0 && (
                <div className="grid grid-cols-2 gap-4">
                  {additionalImages.slice(0, 4).map((img, index) => (
                    <div key={index} className="aspect-w-16 aspect-h-9 cursor-pointer" onClick={() => openGallery(index + 1)}>
                      <img src={img} alt={`${title} - ${index + 1}`} className="w-full h-full object-cover rounded-lg shadow-lg" />
                    </div>
                  ))}
                </div>
              )}

              <div className="space-y-6 mt-8 border-t border-gray-700 pt-6">
                <div className="grid grid-cols-2 gap-4">
                  {client && (
                    <div>
                      <p className="text-sm text-gray-400">Client</p>
                      <p className="font-normal">{client}</p>
                    </div>
                  )}
                  {agency && (
                    <div>
                      <p className="text-sm text-gray-400">Agency</p>
                      <p className="font-normal">{agency}</p>
                    </div>
                  )}
                  {role && (
                    <div>
                      <p className="text-sm text-gray-400">Role</p>
                      <p className="font-normal">{role}</p>
                    </div>
                  )}
                  {year && (
                    <div>
                      <p className="text-sm text-gray-400">Year</p>
                      <p className="font-normal">{year}</p>
                    </div>
                  )}
                  {type && (
                    <div>
                      <p className="text-sm text-gray-400">Type</p>
                      <p className="font-normal">{type}</p>
                    </div>
                  )}
                </div>

                <div className="border-t border-gray-700 pt-6">
                  <ul className="flex flex-wrap gap-2">
                    {technologies.map((tech, index) => (
                      <li key={index} className="bg-gray-800 px-3 py-1 rounded-full text-sm">{tech}</li>
                    ))}
                  </ul>
                </div>

                {link && (
                  <div className="border-t border-gray-700 pt-6">
                    <a 
                      href={link} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="group inline-flex items-center text-400 hover:text-300 transition duration-300"
                    >
                      <span className="mr-2">View Project</span>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-12 transform group-hover:translate-x-2 transition-transform duration-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <About />
      <Footer /> {/* Add the Footer component here */}
      {galleryOpen && (
        <Gallery
          images={allImages}
          startIndex={galleryStartIndex}
          onClose={() => setGalleryOpen(false)}
        />
      )}
    </>
  );
}

export default ProjectPage;
