import React from 'react';
import { Link } from 'react-router-dom';
import { projects } from '../config/constants';

function SelectedWork() {
  // Filter out projects with passwords, exclude the first project, and exclude hidden projects
  const selectedProjects = projects.slice(1).filter(project => !project.password && !project.hidden);

  return (
    <>
      <h2 className="text-[#FFFFFF] text-[22px] leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Selected Work</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-3 gap-y-8 p-4">
        {selectedProjects.map((project, index) => (
          <Link to={`/project/${project.id}`} key={index} className="flex flex-col gap-3 group">
            <div className="relative aspect-w-16 aspect-h-9">
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-full object-cover rounded"
              />
              <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 transition-opacity duration-300 ease-in-out rounded"></div>
            </div>
            <div>
              <h3 className="text-white text-sm">{project.title}</h3>
              <p className="text-gray-400 text-xs mt-1">{project.client} • {project.agency} • {project.type}</p>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
}

export default SelectedWork;
