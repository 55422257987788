import React from 'react';
import Hero from './Hero';
import SelectedWork from './SelectedWork';
import About from './About';
import Footer from './Footer';

function MainContent({ onProjectClick }) {
  return (
    <>
    
      <Hero />
      <SelectedWork />
      <About />
      <Footer />
      
    </>
  );
}

export default MainContent;
