import React from 'react';
import { ABOUT_INFO } from '../config/constants';

function About() {
  return (
    <div className="flex flex-col p-4 @container" id="about">
      <hr className="w-full border-t border-[#333333] mb-4" />
      <div className="flex w-full flex-col gap-6 @[520px]:flex-row @[520px]:justify-between @[520px]:items-center">
        <div className="flex gap-6">
          <div className="flex flex-col justify-center">
            <p
              id="about" 
              className="text-[#CBCBCB] text-2xl font-normal leading-normal"
              dangerouslySetInnerHTML={{ __html: ABOUT_INFO.description }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
