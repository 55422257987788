import React from 'react';
import { Link } from 'react-router-dom';
import { projects } from '../config/constants';

function Hero() {
  const project = projects[0];

  return (
    <div className="@container mt-4">
      <div className="@[480px]:p-4">
        <Link to={`/project/${project.id}`} className="block">
          <div
            className="flex min-h-[480px] flex-col gap-6 bg-cover bg-center bg-no-repeat @[480px]:gap-8 @[480px]:rounded items-center justify-center p-4"
            style={{backgroundImage: `url("${project.image}")`}}
          >
            <div className="flex flex-col gap-2 text-center">
              <h1 className="text-white text-4xl leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:leading-tight @[480px]:tracking-[-0.033em]">
                {project.title}
              </h1>
        
              <div className="mt-2">
                <p className="text-white text-sm">{project.client} • {project.agency} • {project.type}</p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Hero;
