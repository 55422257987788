import React, { useEffect, useRef, useCallback, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import MainContent from './components/MainContent';
import ProjectPage from './components/ProjectPage';
import Container from './components/Container';
import './App.css';
import ReactGA from "react-ga4";

// Scroll settings
const SCROLL_SETTINGS = {
  LERP_FACTOR: 0.1,        // Adjusts the smoothness of the scroll (0.01 to 1)
  SCROLL_MULTIPLIER: 2,  // Adjusts the speed of the scroll
  SCROLL_THRESHOLD: 0.75,   // Minimum difference to continue scrolling animation
};

function AppContent() {
  const scrollRef = useRef({ target: 0, current: 0, animationFrame: null });
  const navigate = useNavigate();
  const location = useLocation();
  const [isTransitioning, setIsTransitioning] = useState(false);  // Start with false
  const [currentLocation, setCurrentLocation] = useState(location);
  const isInitialMount = useRef(true);

  const smoothScroll = useCallback(() => {
    const { current, target } = scrollRef.current;
    const newPosition = lerp(current, target, SCROLL_SETTINGS.LERP_FACTOR);

    window.scrollTo(0, newPosition);
    scrollRef.current.current = newPosition;

    if (Math.abs(target - newPosition) > SCROLL_SETTINGS.SCROLL_THRESHOLD) {
      scrollRef.current.animationFrame = requestAnimationFrame(smoothScroll);
    } else {
      scrollRef.current.animationFrame = null;
    }
  }, []);

  const handleWheel = useCallback((event) => {
    event.preventDefault();

    scrollRef.current.target += event.deltaY * SCROLL_SETTINGS.SCROLL_MULTIPLIER;
    scrollRef.current.target = Math.max(0, Math.min(scrollRef.current.target, document.documentElement.scrollHeight - window.innerHeight));

    if (!scrollRef.current.animationFrame) {
      scrollRef.current.animationFrame = requestAnimationFrame(smoothScroll);
    }
  }, [smoothScroll]);

  useEffect(() => {
    const currentScrollRef = scrollRef.current;
    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleWheel);
      if (currentScrollRef.animationFrame) {
        cancelAnimationFrame(currentScrollRef.animationFrame);
      }
    };
  }, [handleWheel]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      // Trigger fade-in effect on initial load
      setIsTransitioning(true);
      setTimeout(() => {
        setIsTransitioning(false);
      }, 50);
    } else if (location !== currentLocation) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentLocation(location);
        // Reset scroll position when navigating to a new route
        window.scrollTo(0, 0);
        scrollRef.current.target = 0;
        scrollRef.current.current = 0;
        setTimeout(() => {
          setIsTransitioning(false);
        }, 50);  // Small delay to ensure DOM update before fade in
      }, 300);  // This should match the transition duration in CSS
    }
  }, [location, currentLocation]);

  const handleNavigation = useCallback((path) => {
    setIsTransitioning(true);
    setTimeout(() => {
      navigate(path);
    }, 150);  // Half of the transition time to start fading in the new content
  }, [navigate]);

  useEffect(() => {
    // Track page view on route change
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    // console.log("Pageview sent for:", location.pathname + location.search);
  }, [location]);

  return (
    <div className="App bg-[#121212] min-h-screen">
      <Container>
        <Header />
        <div className={`content-wrapper ${isTransitioning ? 'fade-out' : 'fade-in'}`}>
          <Routes location={currentLocation}>
            <Route path="/" element={<MainContent onProjectClick={handleNavigation} />} />
            <Route path="/project/:id" element={<ProjectPage />} />
          </Routes>
        </div>
      </Container>
    </div>
  );
}

function App() {
  useEffect(() => {}, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function lerp(start, end, factor) {
  return (1 - factor) * start + factor * end;
}

export default App;