import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  useEffect(() => {
    const handleSmoothScroll = (e) => {
      const href = e.currentTarget.getAttribute('href');
      if (href && href.startsWith('#')) {
        e.preventDefault();
        const targetId = href.substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach(link => {
      link.addEventListener('click', handleSmoothScroll);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleSmoothScroll);
      });
    };
  }, []);

  return (
    <header className="flex items-center justify-between whitespace-nowrap border-b border-solid border-gray-700 px-10 py-4">
      <div className="flex items-center gap-4 text-[#FFFFFF]">
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <h2 className="text-[#FFFFFF] text-sm font-medium leading-normal">Daniel Samson</h2>
        </Link>
      </div>
      <div className="flex flex-1 justify-end gap-8">
        <div className="flex items-center gap-9">
          <a className="text-[#FFFFFF] text-sm font-medium leading-normal" href="#about">About</a>
          <a className="text-[#FFFFFF] text-sm font-medium leading-normal" href="#contact">Contact</a>
        </div>
      </div>
    </header>
  );
}

export default Header;
